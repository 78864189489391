import React, { useRef, useEffect } from 'react';
import { gsap } from "gsap"
import { ScrollTrigger } from "gsap/ScrollTrigger";

import * as s from './WatermarkTwo.module.scss'

import Watermark from './../../../../images/svgs/logo-alternative.svg';

export const WatermarkTwo = () => {
    const watermarkSVG = useRef(null)
    let watermarkClass;
    
    useEffect(() => {
        gsap.registerPlugin(ScrollTrigger)
        const svgWatermark = watermarkSVG.current;

        if(svgWatermark){
            const anim = gsap.timeline({
                scrollTrigger: {
                  trigger: svgWatermark,
                  start: "center 100%",
                  scrub: 1
                },
            });

            anim.fromTo(svgWatermark, 
                {
                    x: "-5%"
                },
                {
                    x: 0
                }
            )
        }
    }, [])

    return (
        <div ref={watermarkSVG} className={`${s.watermarkContainer} ${watermarkClass}`}>
            <Watermark className={s.watermark} />
        </div>
    );
}