import React from 'react'
import { Seo } from '../../components/Seo/Seo'
import * as s from './FAQ.module.scss';

// All questions imported from this file
import { questions } from './questions';

export const Faq = ({ className, children }) => {
    let questionsMetaData = questions.map(q => {
        return (
            {
                "@type": "Question",
                "name": q.question,
                "acceptedAnswer": {
                "@type": "Answer",
                "text": q.answer
                }
            }
        )
    })

    let questionsHTML = questions.map((q, i) => {
      return (
        <p key={i}>
            <strong>{q.question}</strong><br/>
            {q.answer}
        </p>
      )  
    })

    return (
        <div id="faq" className={`${className} container`}>
            <Seo>
                <script type="application/ld+json">{`
                {
                    "@context": "https://schema.org",
                    "@type": "FAQPage",
                    "mainEntity": ${JSON.stringify(questionsMetaData)} 
                }
                `}</script>
            </Seo>
            <div className="faq">
                <div className="columns">
                    <div className="column">
                        <h2 className="font-serif">
                            Frequently <br/>Asked Questions
                        </h2>
                    </div>
                    <div className="column">
                        <div className={s.questions}>
                            {questionsHTML}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}