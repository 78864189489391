import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { GatsbyImage } from "gatsby-plugin-image";

import { ResponsiveContainer } from './../../components/ResponsiveContainer/ResponsiveContainer'

import InstagramSVG from './../../images/svgs/instagram.svg';
import ExternalSVG from './../../images/svgs/external.svg';
import * as s from './Instagram.module.scss'

export const Instagram = () => {
    const data = useStaticQuery(instagramQuery);

    const instaImages = data.allInstaNode.edges.map((instaImage, index) => {
      return (
        <div key={index} className={s.img}>
            <ResponsiveContainer aspectRatio="1:1">
                <GatsbyImage
                  image={instaImage?.node?.localFile?.childImageSharp?.gatsbyImageData}
                  style={{ position: 'absolute' }} 
                  alt=""
                  />
            </ResponsiveContainer>
        </div>
      );
    });

    return (
        <div className={s.wrapper}>
            <div className={`container ${s.inner}`}>
                <div className={s.text}>
                  <p className="h2">
                      <span className="font-serif">Want to see more? </span><br/>
                      Follow us on the ol’ gram!
                  </p>
                  <a className={s.link} href="https://www.instagram.com/collectivehaus/" target="_blank" rel="noreferrer"><InstagramSVG /> @collectivehaus <span className="sr-only">on Instagram</span> <ExternalSVG /></a>
                </div>
                <div className={s.imgWrapper}>
                  <div className={s.innerImgWrapper}>
                    <div className={s.innerImgGrid}>
                      {instaImages}
                    </div>
                  </div>
                </div>
            </div>
        </div>
    );
}

const instagramQuery = graphql`{
  allInstaNode(limit: 3, sort: {fields: timestamp, order: DESC}) {
    edges {
      node {
        localFile {
          childImageSharp {
            id
            gatsbyImageData(layout: FULL_WIDTH)
          }
        }
      }
    }
  }
}
`