import React from 'react'

import * as s from './ResponsiveContainer.module.scss';

export const ResponsiveContainer = ({ children, aspectRatio, className }) => {
    let aspectRatioPercentage = '56.25%'; // Default to 16:9

    if(aspectRatio){
        if(aspectRatio.includes(':') || aspectRatio.includes('/')){
            let [w,h] = aspectRatio.split(':');
            aspectRatioPercentage = (h / w * 100) + '%';
        } else if(aspectRatio.includes('%')){
            aspectRatioPercentage = aspectRatio;
        } else {
            console.error(`Responsive Container: ${aspectRatio} is not a valid aspect ratio format.`)
        }
    }

    let aspectRatioStyle = {
        paddingTop: aspectRatioPercentage 
    }

    return (
        <div className={`${s.responsiveContainer} ${className ? className : ''}`}>
            {children}
            <div className={s.heightBox} style={aspectRatioStyle}></div>
        </div>
    )
}