import React, { useEffect, useState } from 'react';

import { Seo } from '../Seo/Seo';

import ChangeSVG from './../../images/svgs/change.svg';
import * as s from './ThemeChanger.module.scss';

// Theme names can be found in themes.scss
let colorCombos = [
    {
        text: 'Why didn’t you tell us you hated pink?',
        themeName: 'default'
    },
    {
        text: 'Not a green fan either?',
        themeName: 'green'
    },
    {
        text: 'Orange ya glad it isn’t green?',
        themeName: 'orange'
    },
    {
        text: 'Alright. everyone likes yellow, no?',
        themeName: 'yellow'
    },
    {
        text: 'These colours got you feelin’ blue?',
        themeName: 'blue'
    },
    {
        text: "There's just no pleasing you is there?",
        themeName: 'orange-reverse'
    },
    {
        text: "Well, well, well, looks like we’re back to where we started.",
        themeName: 'default'
    },
    {
        text: 'I’m starting to think you just like doing this.',
        themeName: 'green'
    },
    {
        text: 'Click. Click. Click.',
        themeName: 'orange'
    },
    {
        text: 'Don’t you have something better to do?',
        themeName: 'yellow'
    },
    {
        text: 'Dude... this is just getting sad.',
        themeName: 'blue'
    },
    {
        text: "I give up.",
        themeName: 'orange-reverse'
    },
]

export const ThemeChanger = () => {
    let [currentTheme, setCurrentTheme] = useState(colorCombos[0]);
    let [counter, setCounter] = useState(0); 

    // Update our theme on click
    useEffect(() => {
        if(counter > colorCombos.length - 1){
            setCounter(0);
        } else {
            setCurrentTheme(colorCombos[counter]);
        }
    }, [counter])

    return (
        <>
            <Seo>
                <html 
                    lang="en" 
                    class={`theme-${currentTheme.themeName}`} 
                />
            </Seo>
            <button 
                className={s.themeChanger} 
                onClick={() => setCounter(counter + 1)}
            >
                {currentTheme.text} <ChangeSVG />
            </button>
        </>
    );
}