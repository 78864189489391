import React, { useRef, useEffect } from 'react';
import { gsap } from "gsap"
import { ScrollTrigger } from "gsap/ScrollTrigger";

import * as s from './WatermarkOne.module.scss'

import Watermark from './../../../../images/svgs/text-watermark.svg';

export const WatermarkOne = () => {
    const watermarkSVG = useRef(null)
    let watermarkClass;
    
    useEffect(() => {
        const svgTextPath = watermarkSVG.current?.getElementsByTagName("textPath")[0];
        let queuedAnims = []
        let startOffsetAmount = "100%";

        gsap.registerPlugin(ScrollTrigger)

        if(svgTextPath){
            const anim = gsap.timeline({
                scrollTrigger: {
                  trigger: svgTextPath,
                  start: "center 100%",
                  scrub: 1
                },
            }).call(checkIfScrolled)

            anim.from(svgTextPath, 
                {
                    attr: { startOffset: startOffsetAmount },
                }
            )

            anim.to(svgTextPath, 
                {
                    attr: { startOffset: "0%" },
                }
            )

            let hasScrolled = false;

            function checkIfScrolled() {
                if(!hasScrolled) {
                    queuedAnims.push(this.parent.pause(0));
                }
            }

            function startAnims() {
                hasScrolled = true;
                queuedAnims.forEach(tween => tween.play());
                window.removeEventListener("scroll", startAnims);
            }

            window.addEventListener("scroll", startAnims);
        }
    }, [])

    return (
        <div ref={watermarkSVG} className={`${s.watermarkContainer} ${watermarkClass}`}>
            <Watermark className={s.watermark} />
        </div>
    );
}