import React from 'react'

import { SocialIcons } from '../../components/SocialIcons/SocialIcons';
import { ThemeChanger } from '../../components/ThemeChanger/ThemeChanger';
import LogoBadge from './../../images/svgs/logo-full.svg';

import * as s from './Footer.module.scss';

export const Footer = () => {
    return (
        <footer className={s.footer}>
            <div className="container">
                <LogoBadge className={s.logo} />
                <div className={s.content}>
                    <p>273 Elm Street <br/> Sudbury, Ontario</p>
                    <SocialIcons />
                    <p>705.470.5179</p>
                </div>
                <ThemeChanger />
            </div>
        </footer>
    )
}