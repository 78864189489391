export let questions = [
    {
        question: "Where are you located?",
        answer: "Collective Haus is located in a century old house at the corner of Elm/Cypress streets in the heart of Downtown Sudbury. Come check us out at 273 Elm St, Sudbury, ON P3C 1V5.",
    },
    {
        question: "Where should I park?",
        answer: "We’ve got several dedicated parking spots behind the Haus, but they fill up quickly. Feel free to park along Cypress Street if you have to!"
    },
    {
        question: "What’s the deal with late arrivals?",
        answer: "If you turn up 15 minutes late or more to your appointment, we will have to reschedule you. When you’re late, it makes everyone else late, even the clients who showed up on time which just ain’t fair!"
    },
    {
        question: "Uh, I didn’t show up to my appointment. What happens next? (Cancellations)",
        answer: "At the Haus, we believe in respecting our customers’ time and also our own. If you book with us, and you don’t show up, that ain’t cool. That’s unpaid time lost that could have gone towards another customer. Our fee for no-shows and late cancellations is 100% of the appointment cost, and we will require you to pay 50% towards your next appointment. If you confirm our automated text messages concerning your next appointment, we expect you to show up." 
    },
    {
        question: "What’s the best way to pay for my appointment?",
        answer: "We prefer cash, because, who doesn’t!? If you don’t have that, we accept credit and debit, or you can e-transfer collective.emma@gmail.com"
    }
]
