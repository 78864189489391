import React from 'react'
import { useStaticQuery, graphql } from 'gatsby';
import { Helmet } from "react-helmet";

export const Seo = ({ title, description, image, children }) => {
    const { site } = useStaticQuery(siteMetaQuery);
    const {
        siteTitle,
        defaultDescription,
        defaultOGImg,
        siteUrl
    } = site.siteMetadata;

    var defaultTitle = `${siteTitle} - Salon and Barbershop in Sudbury, Ontario`;

    const seo = {
        title: title || defaultTitle,
        description: description || defaultDescription,
        image: `${siteUrl}/${image || defaultOGImg}`,
        url: siteUrl,
    }

    return (
        <Helmet title={seo.title}>
            <html lang="en" />
            <meta charSet="utf-8" />

            {seo.title && 
                <meta name="title" content={seo.title} />
            }
            {seo.image && 
                <meta name="image" content={seo.image} />
            }
            {seo.description && 
                <meta name="description" content={seo.description} />
            }
            {seo.url && 
                <link rel="canonical" href={seo.url} />
            }


            {seo.title && 
                <meta property="og:title" content={seo.title} />
            }
            {seo.image && 
                <meta property="og:image" content={seo.image} />
            }
            {seo.description && 
                <meta property="og:description" content={seo.description} />
            }
            {seo.url && 
                <meta property="og:url" content={seo.url} />
            }


            {seo.title && 
                <meta name="twitter:title" content={seo.title} />
            }
            {seo.image && 
                <meta name="twitter:image" content={seo.image} />
            }
            {seo.description && 
                <meta name="twitter:description" content={seo.description} />
            }
            {seo.url && 
                <meta property="twitter:url" content={seo.url} />
            }

            <meta property="og:type" content="website" />
            <meta name="twitter:card" content="summary_large_image" />

            <link rel="stylesheet" href="https://use.typekit.net/gft1bwd.css" />

            <script type="application/ld+json">{`
                {
                "@context": "https://schema.org",
                "@type": "HairSalon",
                "name": "Collective Haus",
                "image": "${seo.url}/logo.png",
                "@id": "${seo.url}", 
                "url": "${seo.url}",
                "telephone": "(705) 470-5179",
                "priceRange": "$$",
                "address": {
                    "@type": "PostalAddress",
                    "streetAddress": "273 Elm St",
                    "addressLocality": "Greater Sudbury",
                    "addressRegion": "ON",
                    "postalCode": "P3C 4G2",
                    "addressCountry": "CA"
                },
                "geo": {
                    "@type": "GeoCoordinates",
                    "latitude": 46.4929013,
                    "longitude": -81.0056372
                } ,
                "sameAs": [
                    "https://www.facebook.com/CollectiveHausSudbury/",
                    "https://www.instagram.com/collectivehaus/"
                ] 
                }
            `}</script>
        
            {children}
        </Helmet>
    )
}

const siteMetaQuery = graphql`
  query DefaultSEOQuery {
    site {
      siteMetadata {
        siteTitle: title
        defaultDescription: description
        defaultOGImg: ogImg
        siteUrl
      }
    }
  }
`